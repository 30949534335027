var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[(_vm.$route.meta.title)?_c('h1',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$route.meta.title))]):_vm._e(),_c('div',{staticClass:"mb-4 clearfix"},[_c('VBtn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'inspect/ApplicationDetailView', params: { application: _vm.application } })}}},[_c('VIcon',{domProps:{"textContent":_vm._s('mdi-arrow-left')}}),_vm._v(" Назад ")],1)],1),_c('InspectorList',{attrs:{"show-select":"","headers":[
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Имя', value: 'name', sortable: false },
        { text: 'Компания', value: 'company', sortable: false },
        { text: 'Вид инспектора', value: 'type.text', alias: 'type', options: _vm.types, sortable: false },
        { text: 'ИНН', value: 'inn', sortable: false },
        { text: 'Почта', value: 'email', sortable: false },
        { text: 'В работе', value: 'work', sortable: false },
        { text: 'Всего инспекций', value: 'inspections', sortable: false },
        { text: 'Рейтинг', value: 'rating', sortable: false } ],"items":_vm.list.items,"count":_vm.list.count,"page":_vm.page,"size":_vm.size,"loading":_vm.pending},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.selected.length)?_c('VFooter',{attrs:{"app":""}},[(_vm.pending)?_c('VProgressLinear',{class:_vm.$style.progress,attrs:{"absolute":"","indeterminate":"","color":"primary darken-2"}}):_vm._e(),_c('div',{staticClass:"py-3 grow"},[_c('VBtn',{staticClass:"mr-2",attrs:{"color":"primary","depressed":""},on:{"click":_vm.onSave}},[_vm._v("Добавить")]),_c('VBtn',{staticClass:"mr-2",attrs:{"color":"secondary","depressed":""},on:{"click":function () { return _vm.selected = []; }}},[_vm._v("Отмена")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }